import React, { FC } from "react";
import { PageProps } from "gatsby";
import { Day, Story } from "../types";
import { Seo } from "../components/Seo";
import { Body } from "../components/Body";
import { Title } from "../components/Title";
import { StoryComponent } from "../components/StoryComponent";
import { Explanation } from "../data/Explanation";
import { BackgroundFromKey } from "../components/Background";
import { BackgroundKey, TextColor } from "../enums";

export interface RouteTemplateContext {
  day: Day;
  background: BackgroundKey;
  color: TextColor;
}

const RouteTemplate: FC<PageProps> = ({ pageContext }) => {
  const {
    day: { shortTitle, stories, explanation },
    background,
    color,
  } = pageContext as unknown as RouteTemplateContext;
  return (
    <>
      <BackgroundFromKey k={background} />
      <Seo
        title={shortTitle}
        description={stories
          .map((story) => story.title + (story.time ? ` (${story.time})` : ""))
          .join(", ")}
      />
      <Body>
        <Title text={shortTitle} className={color} />
        <Explanation k={explanation} />
        <div className="w-full flex flex-col -mt-3">
          {stories.map((story: Story, idx) => (
            <StoryComponent key={idx} story={story} />
          ))}
        </div>
      </Body>
    </>
  );
};

export default RouteTemplate;
